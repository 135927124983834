import React from 'react';

function ProductCard({ imgUrl, name, label, description}) {
    let descr = (description.length > 0 && typeof description !== undefined) ? description.slice(0, 90) + " ..." : "No description.";
    
    return (
        <div className="mcard p-3 video-card">
            <img src={imgUrl} style={{width: 286, height: 192, margin: 'auto'}} className="img-fluid" />
            <div className="video-label mt-3">{label}</div>
            <div className="title mt-3">{name}</div>
            <div className="description mt-3">
               {descr}
            </div>
            {/* <div className="mt-3 mb-2">
                <span className="more-text">Learn more</span>
                <img src="assets/images/arrow-up-right.svg" />
            </div> */}
        </div>
    );
}

export default ProductCard;