import React from 'react';
import { Banner } from '../../components/banner';
import { ProductCard } from '../../components/productcard';


function Resources() {
    return (
        <div>
           <Banner 
              {...{
                badge: 'Resources',
                title: <>We’ve provided some resources <br /><span style={{ color: '#08783D' }}>you might need</span></>,
                placeholder: 'Search for a question' 
            }}
           />
            <section id="resources" className="resources">
                <div className="row">
                    <h1>Our Top Resources</h1>
                </div>
                <div className="row justify-content-between gy-4 mt-3">
                        <ProductCard
                            {...{
                                imgUrl: 'assets/images/m1132.png',
                                label: 'Used',
                                name: 'HP LaserJet M1132 MFP Printer',
                                description: 'HP\'s LaserJet M1132 MFP is based around a mono laser printer. While this rules out colour prints, it should mean that black text looks good and prints quickly. The M1132 is a small and reasonably attractive device that, like many home MFPs, is made from black plastic.'
                            }}
                        />
                        <ProductCard
                            {...{
                                imgUrl: 'assets/images/printer.png',
                                label: 'UK Used',
                                name: 'Epson WorkForce Pro WF-M5799 ',
                                description: 'Designed for home offices and small workgroups, the WorkForce Pro WF-M5799 Workgroup Monochrome Multifunction Printer from Epson provides you with quick and efficient printing, scanning, copying, and faxing. Featuring PrecisionCore technology, users will enjoy sharp prints with a 4800 x 1200 dpi'
                            }}
                        />
                        <ProductCard
                            {...{
                                imgUrl: 'assets/images/m1132.png',
                                label: 'Brand New',
                                name: 'HP LaserJet M1132 MFP Printer',
                                description: 'HP\'s LaserJet M1132 MFP is based around a mono laser printer. While this rules out colour prints, it should mean that black text looks good and prints quickly. The M1132 is a small and reasonably attractive device that, like many home MFPs, is made from black plastic.'

                            }}
                        />
                   
                        <ProductCard
                            {...{
                                imgUrl: 'assets/images/m1132.png',
                                label: 'Used',
                                name: 'HP LaserJet M1132 MFP Printer',
                                description: 'HP\'s LaserJet M1132 MFP is based around a mono laser printer. While this rules out colour prints, it should mean that black text looks good and prints quickly. The M1132 is a small and reasonably attractive device that, like many home MFPs, is made from black plastic.'
                            }}
                        />
                        <ProductCard
                            {...{
                                imgUrl: 'assets/images/printer.png',
                                label: 'UK Used',
                                name: 'Epson WorkForce Pro WF-M5799 ',
                                description: 'Designed for home offices and small workgroups, the WorkForce Pro WF-M5799 Workgroup Monochrome Multifunction Printer from Epson provides you with quick and efficient printing, scanning, copying, and faxing. Featuring PrecisionCore technology, users will enjoy sharp prints with a 4800 x 1200 dpi'
                            }}
                        />
                        <ProductCard
                            {...{
                                imgUrl: 'assets/images/m1132.png',
                                label: 'Brand New',
                                name: 'HP LaserJet M1132 MFP Printer',
                                description: 'HP\'s LaserJet M1132 MFP is based around a mono laser printer. While this rules out colour prints, it should mean that black text looks good and prints quickly. The M1132 is a small and reasonably attractive device that, like many home MFPs, is made from black plastic.'

                            }}
                        />
                    
                        <ProductCard
                            {...{
                                imgUrl: 'assets/images/m1132.png',
                                label: 'Used',
                                name: 'HP LaserJet M1132 MFP Printer',
                                description: 'HP\'s LaserJet M1132 MFP is based around a mono laser printer. While this rules out colour prints, it should mean that black text looks good and prints quickly. The M1132 is a small and reasonably attractive device that, like many home MFPs, is made from black plastic.'
                            }}
                        />
                        <ProductCard
                            {...{
                                imgUrl: 'assets/images/printer.png',
                                label: 'UK Used',
                                name: 'Epson WorkForce Pro WF-M5799 ',
                                description: 'Designed for home offices and small workgroups, the WorkForce Pro WF-M5799 Workgroup Monochrome Multifunction Printer from Epson provides you with quick and efficient printing, scanning, copying, and faxing. Featuring PrecisionCore technology, users will enjoy sharp prints with a 4800 x 1200 dpi'
                            }}
                        />
                        <ProductCard
                            {...{
                                imgUrl: 'assets/images/m1132.png',
                                label: 'Brand New',
                                name: 'HP LaserJet M1132 MFP Printer',
                                description: 'HP\'s LaserJet M1132 MFP is based around a mono laser printer. While this rules out colour prints, it should mean that black text looks good and prints quickly. The M1132 is a small and reasonably attractive device that, like many home MFPs, is made from black plastic.'

                            }}
                        />
                </div>
                {/* <div className="row mt-5">
                    <div className="col-md-12 d-flex justify-content-center">
                        <a href="#" className="more-btn">Read more</a>
                    </div>
                </div> */}
            </section>
        </div>
    );
}

export default Resources;