import React from 'react';

function Banner({ badge, title, placeholder}) {
    return (
        <section id="banner" className="d-flex banner flex-column justify-content-center align-items-center text-center">
            <div className="badge mb-4">{badge}</div>
            <h1>{title}</h1>
            {/* <div class="col-md-6 search-wrapper">
                <img src="assets/images/search.svg" />
                <input type="text" class="form-control" placeholder={placeholder} />
            </div> */}
        </section>
    );
}

export default Banner;