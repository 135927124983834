import React from 'react';
import { Link } from 'react-router-dom';
import { ProductCard } from '../../components/productcard';
import VideoCard from '../../components/videocard/VideoCard';
import WhatWeDoCard from '../../components/whatwedocard/WhatWeDoCard';

function Landing() {
    return (  
        <main>
              <section id="hero" className="hero d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="hero-text col-md-6 d-flex flex-column justify-content-center order-2 order-lg-1">
                            <h1>
                                Welcome to the Support Portal that{" "}
                                <span style={{ color: "#08783D" }}>Puts You First!</span>
                            </h1>
                            <p>
                                Find answers to frequently asked questions, lodge
                                <br /> complaints, find self-help videos, and so much more.
                            </p>
                            <div className="hero-btn d-flex">
                                <Link to="/account/signup" className="signup-btn">
                                    Create an account
                                </Link>
                                <Link to="/account/signin" className="signin-btn">
                                    Sign in
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 order-1 order-lg-2">
                            <img src="assets/images/hero-img.png" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
             <section id="videosection" className="videosection">
                {/* <div className="container"> */}
                <div className="row justify-content-center">
                    <h1>Do it yourself</h1>
                    <p>
                        Step-by-step videos you can follow to carry out basic
                        troubleshooting and quick fixes.
                    </p>
                </div>
                <div className="row justify-content-between gy-4 mt-3">
                    <VideoCard />
                    <VideoCard />
                    <VideoCard />
                </div>
                {/* </div> */}
            </section>
            <section id="products" className="products">
                <div className="row">
                    <h1>New Products</h1>
                </div>
                <div className="row justify-content-between gy-4 mt-3">
                    <ProductCard
                        {...{
                            imgUrl: 'assets/images/m1132.png',
                            label: 'Used',
                            name: 'HP LaserJet M1132 MFP Printer',
                            description: 'HP\'s LaserJet M1132 MFP is based around a mono laser printer. While this rules out colour prints, it should mean that black text looks good and prints quickly. The M1132 is a small and reasonably attractive device that, like many home MFPs, is made from black plastic.'
                        }}
                    />
                    <ProductCard
                        {...{
                            imgUrl: 'assets/images/printer.png',
                            label: 'UK Used',
                            name: 'Epson WorkForce Pro WF-M5799 ',
                            description: 'Designed for home offices and small workgroups, the WorkForce Pro WF-M5799 Workgroup Monochrome Multifunction Printer from Epson provides you with quick and efficient printing, scanning, copying, and faxing. Featuring PrecisionCore technology, users will enjoy sharp prints with a 4800 x 1200 dpi'
                        }}
                    />
                    <ProductCard
                        {...{
                            imgUrl: 'assets/images/m1132.png',
                            label: 'Brand New',
                            name: 'HP LaserJet M1132 MFP Printer',
                            description: 'HP\'s LaserJet M1132 MFP is based around a mono laser printer. While this rules out colour prints, it should mean that black text looks good and prints quickly. The M1132 is a small and reasonably attractive device that, like many home MFPs, is made from black plastic.'

                        }}
                    />
                </div>
            </section>
            <section id="whatwedo" className="whatwedo">
                <div className="row justify-content-center">
                    <h1>Do it yourself</h1>
                    <p>Step-by-step videos you can follow to carry out basic troubleshooting and quick fixes.</p>
                </div>
                <div className="row justify-content-between gy-4 mt-3">
                    <WhatWeDoCard
                        {...{
                            imgUrl: 'assets/images/bulb.svg',
                            title: 'We attend to your complaints promptly',
                            description: 'Our ethics are built on trust, value creation, quality service, corporate governance and accountability.'
                        }}
                    />
                    <WhatWeDoCard
                        {...{
                            imgUrl: 'assets/images/bulb.svg',
                            title: 'We fix your item timely',
                            description: 'Our ethics are built on trust, value creation, quality service, corporate governance and accountability.'
                        }}
                    />
                    <WhatWeDoCard
                        {...{
                            imgUrl: 'assets/images/bulb.svg',
                            title: 'We are customer centric',
                            description: 'Our ethics are built on trust, value creation, quality service, corporate governance and accountability.'
                        }}
                    />
                </div>
            </section>
        </main>
    );
}

export default Landing;