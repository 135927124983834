import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAuthContext } from '../../../context/auth_context';
//import { Services } from '../../../services';
import '../../../style/signin.css';

function TrackComplaint() {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const { signIn, logIn } = useAuthContext();
    const navigate = useNavigate();

    
    const onSubmit = (data) => {
       if(data){
        navigate("/complaint/result"); 
        //   Services.post("/authenticate", data)
        //    .then(res => {
        //     if(res.data){
        //         console.log(res.data);
        //         signIn({...res.data.data, login: true});
        //         navigate("/dashboard");
        //     }
        //    })
        //    .then(err => {
        //       console.log("Error: "+ err);
        //    })
       }
    }
    return (
<>
<ToastContainer  autoClose={1000}/>
        <div className="accountverified-container signin flex-column">
        <div className="row mb-3">
                    <Link to="/" className="col-md-12">
                    <img src="/assets/images/home-logo.svg" alt="" />
                    </Link>
                </div>
            <div className="row justify-content-center align-items-center d-sm-none d-md-flex m-2">
                <div className="mcard h-auto signin-card">
                    <div className="d-flex flex-column h-100 justify-content-center align-items-center text-left">
                        <h3 className="title text-center">Track complaint</h3>
                        <p className="subtitle">Enter your complaint details to see the progress status of your complaint.</p>
                        <form className="row" onSubmit={handleSubmit(onSubmit)}>
                            <div class="col-md-12 mb-2">
                                <label for="email" class="form-label">Email address</label>
                                <input type="email" class="form-control" id="emailaddress" placeholder=""
                                  {...register("emailaddress", { required: true })}
                                  />
                                  {errors.emailaddress && errors.emailaddress.type === "required" && <p className="error">Email address is required.</p>}
                             
                            </div>
                            <div class="col-md-12 mb-0">
                                <label for="tracking" class="form-label">Tacking ID</label>
                                <input type="text" class="form-control" id="tracking" placeholder=""
                                  {...register("trackingid", { required: true })}
                                  />
                                  {errors.trackingid && errors.trackingid.type === "required" && <p className="error">Tracking id is required.</p>}
                             
                            </div>
                            <div className="col-md-12 mb-3 mt-2">
                                <div className="forget-password" style={{color: '#1D2939'}}>Check your email for your tracking ID</div>
                            </div>
                            <div className="col-md-12 mb-3">
                            <button type="submit" class="btn more-btn signin-btn w-100">Submit</button>
                           </div>
                        </form>
                        
                    </div>
                </div>
            </div>

        </div>
        </>
    );
}

export default TrackComplaint;