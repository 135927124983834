import React, { useState, useEffect, useRef } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Drawer } from 'antd';
import ChatInput from './ChatInput';
import ChatBody from './ChatBody';

const URL = process.env.HUB_ADDRESS ?? "https://localhost:7228/bos/message"
function Chat({isOpen}) {
    const [connection, setConnection] = useState(null);
    const [open, setOpen] = useState(false);
    const [chats, setChats] = useState([]);
    const latestChat = useRef(null);

    latestChat.current = chats;
useEffect(() => {

}, [isOpen])
    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(URL,{
                headers: {
                    "SecretKey": "02d60e80-a540-414e-aefc-013628e9359d"
                }
            })
            .withAutomaticReconnect()
            .build();

        setConnection(connection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    console.log('Connected!');

                    connection.on('ReceiveMessage', message => {
                        const updatedChat = [...latestChat.current];
                        updatedChat.push(message);

                        setChats(updatedChat);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    const sendMessage = async (user, message) => {
        const chatMessage = {
            user: user,
            message: message
        };

        if (connection.connectionStarted) {
            try {
                await connection.send('SendMessage', chatMessage);
            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            alert('No connection to server yet.');
        }
    }
    const onClose = () => {
        setOpen(false);
    };
    return (
        <Drawer
                    title={<div className="drawer">
                        <div className="title">Send a message</div>
                        <p className="subtitle">We promise to respond...</p>
                    </div>}
                    placement="right"

                    onClose={onClose}
                    open={open}
                    closable={false}
                    className="drawer-wrapper"
                    headerStyle={{ height: 100 }}
                    extra={
                        <div onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
                            <img src="/assets/images/close-drawer.svg" />
                        </div>
                    }
                    footer={
                        <ChatInput />
                    }>
                    <div className="row">
                        <ChatBody chats={
                            [{
                                type: "incoming"
                            }]
                        }/>
                    </div>
                </Drawer>
    );
};

export default Chat;