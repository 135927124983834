import React from 'react';
import { VideoPlayer } from '../player';

function VideoCard() {
    return (
        <div className="mcard p-3 video-card">
            <VideoPlayer />
            <div className="video-label mt-3">label</div>
            <div className="title mt-3">Fix name</div>
            <div className="description mt-3">
                We would love to respond to your queries. Feel free to get in touch with us
                Lorem.
            </div>
            {/* <div className="mt-3 mb-2">
                <span className="more-text">Learn more</span>
                <img src="assets/images/arrow-up-right.svg" />
            </div> */}
        </div>
    );
}

export default VideoCard;