import logo from './logo.svg';
import './App.css';
import React, { Suspense } from 'react';

import { Route, Routes } from 'react-router-dom';


import { DashboardLayout, LandingLayout } from './layouts';

import { FAQ, Landing, Resources } from './pages/landing';
import { LogComplaint, TrackComplaint, TrackComplaintResult } from './pages/landing/complaintmgt';

import { AccountVerified, ForgetPassword, ResetPassword, SignIn, SignUp } from './pages/landing/onboarding';
import { Dashboard } from './pages/dashboard';
import { Complaint, ComplaintView } from './pages/complaint';
import { Report } from './pages/report';
import { Settings } from './pages/settings';
import { CustomFallback } from './components/fallbackspinner';
import { ThreeDots } from 'react-loader-spinner';
import { ProtectedRoute } from './components/protected';
import { ErrorPage } from './pages/error';


//const LogComplaint = React.lazy(() => import("./pages/landing/complaintmgt/LogComplaint"));
// const LogComplaint = React.lazy(() => {
//   return Promise.all([
//     import("./pages/landing/complaintmgt/LogComplaint"),
//     new Promise(resolve => setTimeout(resolve, 300))
//   ])
//   .then(([moduleExports]) => moduleExports);
// });
// const DashboardLayout = React.lazy(() => {
//   return Promise.all([
//     import("./layouts/DashboardLayout"),
//     new Promise(resolve => setTimeout(resolve, 1000))
//   ])
//   .then(([moduleExports]) => moduleExports);
// });

function App() {
  return (
    // <Suspense fallback={ <div className="d-flex justify-content-center align-items-center" style={{position: 'absolute', left: 0, right: 0, height: '100%'}}><ThreeDots
    // height="80" 
    // width="80" 
    // radius="9"
    // color="#08783D" 
    // ariaLabel="three-dots-loading"
    // visible={true}
    //  /></div>}>
    <Routes>
       {/* <Route path="*" element={<ErrorPage />}></Route> */}
      <Route path="/" element={<LandingLayout />}>
     
        <Route index element={<Landing />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/complaint/result" element={<TrackComplaintResult />} />
      </Route>

      <Route path="/complaint/log" element={
        <LogComplaint />
      } />
      <Route path="/complaint/track" element={<TrackComplaint />} />

      <Route path="/account/signup" element={<SignUp />} />
      <Route path="/account/signin" element={<SignIn />} />
      <Route path="/account/forgetpassword" element={<ForgetPassword />} />
      <Route path="/account/passwordreset/:resetcode" element={<ResetPassword />} />
      <Route path="/account/verify" element={<AccountVerified />} />

      <Route path="/dashboard" element={
        <DashboardLayout />
      }
      >
        <Route index element={
          <ProtectedRoute redirectUrl="/account/signin">
            <Dashboard />
          </ProtectedRoute>} />
        <Route path="complaints" element={
          <ProtectedRoute redirectUrl="/account/signin">
            <Complaint />
          </ProtectedRoute>} />
        <Route path="complaint/view" element={
          <ProtectedRoute redirectUrl="/account/signin">
            <ComplaintView />
          </ProtectedRoute>} />
        <Route path="reports" element={
          <ProtectedRoute redirectUrl="/account/signin">
            <Report />
          </ProtectedRoute>} />
        <Route path="settings" element={
          <ProtectedRoute redirectUrl="/account/signin">
            <Settings />
          </ProtectedRoute>} />
      </Route>
     
    </Routes>
    // </Suspense>
  );
}

export default App;
