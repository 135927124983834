import React from 'react';
import { Banner } from '../../components/banner';


function FAQ() {
    return (
        <>
            <Banner
                {...{
                    badge: 'FAQs',
                    title: <>We answered some questions <br /><span style={{ color: '#08783D' }}>you might have.</span></>,
                    placeholder: 'Search for a question'
                }}
            />
            <section id="faqs" className="faqs">
                {
                    faqs.map((datum, i) => {
                        return (
                            <div key={(i + datum.key)} className="row mb-3">
                                
                                <div className="col-md-3 mb-3 mt-3">
                                    <h3>{datum.category}</h3>
                                </div>
                                <div className="col-md-9 mb-3 mt-3">
                                    <div className="accordion accordion-flush" id={"accordion" + (i + datum.key)}>
                                        {
                                            datum.list.map((data, j) =>
                                                <div key={datum.category + (j + datum.key)} className="accordion-item">
                                                    <h2 className="accordion-header" id={"flush-heading" + (j + datum.key)}>
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse" + (j + datum.key)} aria-expanded="true" aria-controls={"flush-collapse" + (j + datum.key)}>
                                                            {data.question}
                                                        </button>
                                                    </h2>
                                                    <div id={"flush-collapse" + (j + datum.key)} className="accordion-collapse collapse" aria-labelledby={"flush-heading" + (j + datum.key)} data-bs-parent="#accordion">
                                                        <div className="accordion-body">{data.answer}</div>
                                                    </div>
                                                </div>)
                                        }
                                    </div>
                                </div>
                            </div>)
                    })
                }
            </section>
        </>
    );
}

export default FAQ;

const faqss = [
    {
        category: "",
        list: [
            {
                question: "",
                answer: ""
            }
        ]
    }
]
const faqs = [
    {
        key: "printer",
        category: "Printing and Ribbon Problems",
        list: [
            {
                question: "The colour on my cards looks very washed out and faded, what can I do?",
                answer: "Use the cleaning swap to clean the printhead, Change the ribbon or replace the ribbon."
            },
            {
                question: "I have updated the firmware but the image still looks washed out.",
                answer: "Contact CAS for support. "
            },
            {
                question: "I'm getting a white line going down the entire face of my card on every card.",
                answer: "Use the cleaning swap to clean the printhead. If the issues persist contact CAS for support."
            },
            {
                question: "I can see dust and / or dirt on my cards after printing even though they look and feel clean when they are loaded into the printer.",
                answer: "Change the cleaning sleeve. Clean the printer input hopper."
            },
            {
                question: "I'm getting very blotchy prints.",
                answer: "Check or replace the printer ribbon. Check the printer calibration."
            },
            {
                question: "The dye film starts to stick to the card after a run of 20+ cards causing the printer to jam.",
                answer: "Use the cleaning card on the printer. If issue persist contact CAS for support."
            },
            {
                question: "Why is the printer printing faintly on card on cards?",
                answer: "Use the cleaning swap to clean the printer print head. Check or replace the printer ribbon. If issue persist contact CAS for support."
            },
            {
                question: "How many cards can the ribbon print?",
                answer: "Monochrome Black Ribbon - 1,500 Prints, Entrust Datacard YMCKT Ribbon - 250 Prints, YMCKT Full Color Printer Ribbon - 500 Prints."
            }
        ]
    },
    {
        key: "ribbon",
        category: "Ribbons",
        list: [
            {
                question: "Do the SD Series card printers use the same ribbons as the SP Series printers?",
                answer: "Yes, The SD series card printer are compatible with the SP Series printers."
            },
            {
                question: "What is the shelf life for the supplies?",
                answer: "The Shelf life for a supply is subject to usage."
            },
            {
                question: "Can I reuse the printer ribbon?",
                answer: "No, the ribbon cannot be reuse it might damage the print head."
            },
            {
                question: "How can the printer ribbon be replaced or change?",
                answer: "Check the link on the website on how to replace a printer ribbon, or contact CAS for support on how to replace the ribbon."
            },
            {
                question: "How can the ribbon be load or fix?",
                answer: "Check the link on the website on how to load a printer ribbon, or contact CAS for support on how to load the ribbon."
            }
        ]
    },
    {
        key: "encoding",
        category: "Encoding",
        list: [
            {
                question: "Is magnetic stripe field upgradeable?",
                answer: "Yes, the magnetic stripe field is upgradeable."
            },
            {
                question: "What smart card encoding options are available?",
                answer: "Check the user guild or contact CAS support for clarifications and explanations."
            },
            {
                question: "Can I purchase the printers and upgrade it for smart card encoding at a later time?",
                answer: "Yes, The printer upgrade for smart card encoding is available."
            }
        ]
    },
    {
        key: "network",
        category: "Network",
        list: [
            {
                question: "Does a user need access to the internet or a network in order to use the Print Manager?",
                answer: "No, the user doesn’t need access to the internet or a network in order to use the Print Manager?"
            },
            {
                question: "What happens when the network is down with the browser‐based Print Manager?",
                answer: "The print manager keeps working as it doesn’t require internet or network."
            },
        ]
    },
    {
        key: "operating",
        category: "Operating Systems",
        list: [
            {
                question: "Is Windows 7 supported?",
                answer: "Yes, It support on the present available windows."
            },
            {
                question: "How about support for MAC operating systems?",
                answer: "It is supportive with MAC operating systems."
            }
        ]
    },
    {
        key: "driver",
        category: "Drivers and Firmware",
        list: [
            {
                question: "How do I know which Driver and Firmware version are installed?",
                answer: "Contact CAS support on driver and firmware new version to a installation."
            },
            {
                question: "My printer won’t install via USB, the Driver installation doesn’t recognise the printer being plugged in.",
                answer: "Restart the printer while the printer is on, replace the USB cord, if issues persist contact CAS for support."
            }
        ]
    },
    {
        key: "card",
        category: "Card Jam",
        list: [
            {
                question: "How can I fix card jam problems?",
                answer: "Remove the card inside the printer. Use the cleaning card on the printer. If issues persist contact CAS for support."
            },
            {
                question: "How do I use the printer cleaning card?",
                answer: "Check the link on the website on how to load a printer ribbon"
            },
            {
                question: "How can I fix flipper rotation problems or flipper card jams?",
                answer: "Remove the card at the flipper. Use the cleaning card on the printer. If issues persist contact CAS for support."
            }
        ]
    },
    {
        key: "bvn",
        category: "BVN",
        list: [
            {
                question: "What can I do to make sure the LF10 fingerprint scanner is operational?",
                answer: "Proper maintenance of the LF10 fingerprint scanner."
            },
            {
                question: "How can I link my keyboard to my tab?",
                answer: "The Keyboard can be link to the tab through Bluetooth."
            },
            {
                question: "How do I scan documents with the Logitech?",
                answer: "Convert the system camera to the Logitech."
            },
            {
                question: "In the event that the BVN platform does not load.",
                answer: "Contact Nibss for support."
            },
            {
                question: "How do I set up the BVN SDK?",
                answer: "Contact Nibss for support."
            }
        ]
    }
]
// const faqs = [
//     {
//         question: "What is ArgonePay?",
//         answer: "ArgonePay is a buy now, pay later platform that allows customers to spread the cost of their purchases over multiple payments. As a merchant, you can offer this payment option to your customers at checkout."
//     },
//     {
//         question: "How does ArgonePay work?",
//         answer: "When customers select ArgonePay at checkout, they can complete their purchase without paying the full amount upfront. Instead, they make a down payment and then make additional payments over a set period of time. ArgonePay pays the merchant the full purchase amount while we follow up with the customer for repayment."
//     },
//     {
//         question: "How do I sign up to be a merchant?",
//         answer: "You can sign up for ArgonePay by creating a merchant account. Once you’re approved, you will receive instructions on how to integrate the platform into your website or point-of-sale-system."
//     },
//     {
//         question: "How long does it take to receive payments from ArgonePay?",
//         answer: "We typically pay merchants within a few business days of the transaction being processed. However, in some cases, payments may be delayed due to issues such as fraud checks or processing delays from our payment processors."
//     },
//     {
//         question: "What happens if a customer defaults on their payments?",
//         answer: "If a customer defaults on their payment, ArgonePay is responsible for collecting the remaining balance. We may work with collection agencies to take legal action to recover the funds. However, merchants are not responsible for any losses incurred as a result of customer defaults."
//     },
//     {
//         question: "What kind of support does ArgonePay offer to merchants?",
//         answer: "We offer comprehensive support to our merchant partners, including technical support for integrating the platform into your website or point-of-sale-system, as well as customer service support for addressing any issues that may arise with customers using ArgonePay"
//     },
//     {
//         question: "Can I offer BNPL for some of my products or services, or do I have to offer it for everything?",
//         answer: "You have flexibility to choose which products or services you want to offer BNPL for."
//     },
//     {
//         question: "What are the fees associated with using ArgonePay?",
//         answer: "We charge a fee for each transaction processed through our platform. The exact fee depends on several factors, including size of the transaction, the creditworthiness of the customer, and the risk associated with the purchase."
//     }
// ]