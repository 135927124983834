import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { ActivityCard } from '../../components/activitycard';
import { BreakdownCard } from '../../components/breakdowncard';
import { ChartCard } from '../../components/chartcard';
import { MetricCard } from '../../components/metriccard';
import { useAuthContext } from '../../context/auth_context';
import Services from '../../services/Service';
import { getFormatedDate, preparePlotData } from '../../utils';

const { RangePicker } = DatePicker;
const months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
}

function Dashboard() {
    const { user } = useAuthContext();
    const [ complaint, setComplaint ] = useState({});
    const [ breakdown, setBreakdown ] = useState({});
    const [ plot, setPlot ] = useState([]);
    const [ activities, setActivities ] = useState([]);

    useEffect(() => {
        console.log(user)
           Services.post("/customer/dashboard", {
            emailAddress: user.email
           })
           .then(res => {
            if(res.data.data){
                console.log(res.data);
                setComplaint(res.data.data);
                setBreakdown(res.data.data.breakdown);
                setPlot(preparePlotData(res.data.data.plots));
                Services.getWithAuth(`/customer/activities/${user.id}`, user.token)
                   .then(response => {
                    if(response.data){
                        console.log(response.data);
                        setActivities(response.data.data);
                    }
                })
                .catch(err => {
                    console.log("Error: "+ err);
                })
            }
           })
           .catch(err => {
              console.log("Error: "+ err);
           }) 
    }, [])
const onFilterRange = (value) => {
    Services.post(`/customer/dashboard?days=${value}`, {
        emailAddress: user.email
       })
       .then(res => {
        if(res.data.data){
            console.log(res.data);
            setComplaint(res.data.data);
            setBreakdown(res.data.data.breakdown);
            setPlot(preparePlotData(res.data.data.plots));
        }
       })
       .then(err => {
          console.log("Error: "+ err);
       }) 
}
const onFilterDate = (value) => {
    Services.post(`/customer/dashboard?startdate=${getFormatedDate(value[0], "/")}&enddate=${getFormatedDate(value[1], "/")}`, {
        emailAddress: user.email
       })
       .then(res => {
        if(res.data.data){
            console.log(res.data);
            setComplaint(res.data.data);
            setBreakdown(res.data.data.breakdown);
            setPlot(preparePlotData(res.data.data.plots));
        }
       })
       .then(err => {
          console.log("Error: "+ err);
       }) 
}
    return (
        <div className="mt-5">
            <div className="container welcome flex-column px-4">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title">Welcome back, {user.fullname}</div>
                        <p className="subtitle">Track, manage and your complaints.</p>
                    </div>
                </div>
                <div className="row g-5">

                    <MetricCard
                        {...{
                            title: 'Total complaints',
                            amount: complaint.total || 0,
                            type: 'total'

                        }}
                    />
                    <MetricCard
                        {...{
                            title: 'Closed complaints',
                            amount: complaint.closed || 0,
                            type: 'closed',
                            className: "metric-card total"
                        }}
                    />
                    <MetricCard
                        {...{
                            title: 'Opened complaints',
                            amount: complaint.opened || 0,
                            type: 'opened',
                            className: "metric-card total"
                        }}
                    />
                </div>
                <ChartCard data = {plot} onFilterWithRange={onFilterRange} onFilterWithDate={onFilterDate} />
                <div className="row mb-5">
                    <div className="col md-7">
                        <ActivityCard data={activities} />
                    </div>
                    <div className="col-md-5">
                        <BreakdownCard 
                           closed={breakdown && breakdown.closed} 
                           opened={breakdown && breakdown.opened}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;