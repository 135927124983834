import * as signalR from "@microsoft/signalr"
const URL = process.env.REACT_APP_HUB_ADDRESS; //or whatever your backend port is
const KEY = process.env.REACT_APP_SECRET_KEY;
class Connector {
  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL, {
            headers: {
                "SecretKey": KEY
            }
        })
      .withAutomaticReconnect()
      .build()
    this.connection.start().catch(err => console.log(err))
    this.events = (onCustomerMessageReceived, onNewActivity) => {
      this.connection.on("adminMessageReceived", (message) => {
        console.log("Admin Recieved");
        onCustomerMessageReceived(message)
      });
      this.connection.on("ReceiveMessagee", (message) => {
        onNewActivity(message);
      });
    }
  }
  newMessage = messages => {
    this.connection
      .send("newMessage", "foo", messages)
      .then(x => console.log("sent"))
  }
  static getInstance() {
    if (!Connector.instance) Connector.instance = new Connector()
    return Connector.instance
  }
}
export default Connector.getInstance