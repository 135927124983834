import React from 'react';
import { formatDistanceToNow } from 'date-fns';

function ActivityListItem({ type, message, actor, createdDate }) {
    let item = message.split(",");
    const renderActivity = (type) => {
        let element = null;
        switch (type) {
            case "update":
                element = <li className="list-group-item d-flex justify-content-between p-2 px-4">
                <div className="row d-flex gap-1 flex-row mx-0">
                    <div className="col-md-3 avater-wrapper">
                        <img src="/assets/images/bell-green.svg" />
                    </div>
                <div className="col-md-9 ms-2 me-auto">
                    <div className="heading">Status update</div>
                    <div className="sub-heading"><strong className="fw-bold">{actor}</strong> updated complaint <strong>{item[0]}</strong> to <strong>{item[1]}</strong></div>
                </div></div>
            <span>{formatDistanceToNow(new Date(createdDate), { includeSeconds: false, addSuffix: true })}</span>
        </li>;
                break;
            case "assign":
                element = 
                <li className="list-group-item d-flex justify-content-between p-2 px-4">
            <div className="row d-flex gap-1 flex-row mx-0">
                <div className="col-md-3 avater-wrapper">
                    <img src="/assets/images/bell-green.svg" />
                </div>
                <div className="col-md-9 ms-2 me-auto">
                    <div className="heading">Assigned update</div>
                    <div className="sub-heading"><strong className="fw-bold">{actor}</strong> has assigned <strong>CAS145638836</strong> complaint to an engineer<strong></strong></div>
                </div>
                </div>
                <span>{formatDistanceToNow(new Date(createdDate), { includeSeconds: false, addSuffix: true })}</span>
            </li>
                break;
            case "closed":
                element = 
                <li className="list-group-item d-flex justify-content-between p-2 px-4">
            <div className="row d-flex gap-1 flex-row mx-0">
                <div className="col-md-3 avater-wrapper">
                    <img src="/assets/images/bell-green.svg" />
                </div>
                <div className="col-md-9 ms-2 me-auto">
                    <div className="heading">Closed update</div>
                    <div className="sub-heading"><strong className="fw-bold">{actor}</strong> has closed complaint <strong>CAS145638836</strong></div>
                </div>
                </div>
                <span>{formatDistanceToNow(new Date(createdDate), { includeSeconds: false, addSuffix: true })}</span>
            </li>;
                break;

        }
        return element;
    }
    return (
        <>
                {renderActivity(type)}
            </>
    );
}

export default ActivityListItem;