import React, { useState } from 'react';

function ChatInput({sendMessage}) {
    const [message, setMessage] = useState("");

    const handleSendMessage = (e) => {
        //e.preventDefault();

        if (message) {
            sendMessage(message);
        }
        else {
            alert('Please supply a message to send.');
        }
    }

    const onMessageChange = (e) => {
        setMessage(e.target.value);
    }
    return (
        <div className="d-flex gap-2 justify-content-center">
            <div class="col-md-11 search-wrapper">
                <input type="text" class="form-control" value={message} onChange={onMessageChange} placeholder="Enter Ticket ID" />
            </div>
            <div className="col-md-1">
                <div onClick={handleSendMessage} id="mobile-logo" className="rounded-btn" style={{ padding: '8px 18px 8px 18px', cursor: 'pointer' }}>
                    <img src="/assets/images/send.svg" className="" />
                </div>
            </div>
        </div>
    )
};

export default ChatInput;