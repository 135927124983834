import React from 'react';

function WhatWeDoCard({ imgUrl, title, description}) {
    let descr = (description.length > 104 && typeof description !== undefined) ? description.slice(0, 104) + " ..." : 
    (description === "" ? "No description." : description);
    
    return (
        <div className="mcard p-3 video-card p-4">
            <img src={imgUrl} style={{width: 100, height: 100}}/>
            <div className="title mt-3">{title}</div>
            <div className="description mt-3 mb-2">
               {descr}
            </div>
        </div>
    );
}

export default WhatWeDoCard;