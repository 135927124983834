import React from 'react';

import axios from "axios";
import { useLocation } from "react-router-dom";
import { TokenServices } from './services';
const data = require('./data.json');
//const cities = require('./cities.json');

const Province = {
    getStates: () => ({
        slug: Object.keys(data),
        name: Object.values(data).map(v => v.name),
    }),
    getStateObj: () => {
        let obj = []
        for (const key in data) {
            obj.push({
                "key": key,
                "value": data[key].name
            })
        }
        return obj;
    },
    //getCities: () => cities.map((item, i) => item.city),

    //getCitiesObj: (name) => cities.filter((item, i) => item.admin_name.toLowerCase() === name.toLowerCase()).sort((a, b) => b.city - a.city),
    getStateByName: (name) => data[name],
    getStateById: (id) => Object.values(data).find(v => v.id.toString() === id.toString()),
    getStateByCapital: (capital) => Object.values(data).find(v => v.capital.toLowerCase() === capital.toLowerCase()),
    getLocalGovtCount: (name) => data[name].locals.length,
    getLocalGovtByName: (name) => data[name].locals,
    searchByState: (name) => Object.values(data).filter(v => v.name.toLowerCase().indexOf(name.toLowerCase()) > -1),
    searchByLocalGovt: (local) =>
        [].concat.apply([], Object.values(data).map(v => v.locals
            .filter(t => t.name.toLowerCase().indexOf(local.toLowerCase()) > -1)
        )),

}
//console.log("One: ", Province.getLocalGovtByName("lagos"));
//console.log(Province.getStates().slug.map((item, i) => item).map((ij, i) => Province.getStatesObj()[ij] ));
//console.log("Two: ", Province.getStateObj());
//axios.defaults.withCredentials = true;
const URL = process.env.REACT_APP_BASE_URL

const http = axios.create({
    baseURL: URL, 
    headers: {
        "Content-type": "application/json",
    },
});
http.interceptors.request.use(
    (config) => {
      const token = TokenServices.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
http.interceptors.response.use((res) => {
    return res;
}, async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/account/signin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            
            try {
                const localaccesstoken = TokenServices.getLocalAccessToken();
                const localrefreshToken = TokenServices.getLocalRefreshToken();
                const rs = await http.post("/user/refresh", {
                    refreshToken: localrefreshToken,
                    token: localaccesstoken
                },  {
                    headers: {
                  'Access-Control-Allow-Origin': '*', 
                    'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "SecretKey": process.env.REACT_APP_SECRET_KEY
                }
            });

                const { token, refreshToken } = rs.data;
                TokenServices.updateLocalAccessToken(token, refreshToken);

                return http(originalConfig);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }
    }
    return Promise.reject(err);
})

const getFormatedDate = (date, sepratedStr) => {

    const months = {
        0: 'January',
        1: 'February',
        2: 'March',
        3: 'April',
        4: 'May',
        5: 'June',
        6: 'July',
        7: 'August',
        8: 'September',
        9: 'October',
        10: 'November',
        11: 'December'
    }
    var newDate = new Date(date);

    const monthIndex = newDate.getMonth();
    const monthName = months[monthIndex];
    const year = newDate.getFullYear();
    const day = newDate.getDate();
    if(typeof sepratedStr == undefined || sepratedStr === ""){
        return day + " " + monthName + " " + year;
    }else{
        return day + sepratedStr + monthIndex + sepratedStr + year;
    }
}
const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const renderStatus = (status) => {
    let render = "";
    switch (status) {
        // case 1:
        //    render = <p class="alert alert-secondary p-1 mb-0 text-center">Order</p>;
        //   break;
        //   case 2:
        //     render = <p class="alert alert-primary p-1 mb-0 text-center">Processing</p>;
        //    break;
        //    case 3:
        //    render = <p class="alert alert-danger p-1 mb-0 text-center">Rejected</p>;
        //   break;
        //   case 4:
        //    render = <p class="alert alert-success p-1 mb-0 text-center">Fulfilled</p>;
        //   break;  

        case 1:
            render = <p class="alert alert-primary p-1 mb-0 text-center">Processing</p>;
            break;
        case 2:
            render = <p class="alert alert-danger p-1 mb-0 text-center">Pending</p>;
            break;
        case 3:
            render = <p class="alert alert-success p-1 mb-0 text-center">Completed</p>;
            break;
    }
    return render;
}
const getNGCurrence = (money) => {
    return new Intl.NumberFormat('en-NG', {
        currency: 'NGN',
        style: 'currency'
    }).format(parseInt(money || 0));
}
const preparePlotData = (data) => {
    const labels = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        "Jun",
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];
    let list = [];
    let item = data[data.length - 1].x;
    let size = labels.indexOf(item) + 1;
    for (let j = 0; j < data.length; j++) {
        for (let i = 0; i < size; i++) {
            if (labels[i] === data[j].x) {
                list.push([labels[i], data[j].y])
            } else {
                list.push([labels[i], 0])
            }
        }
    }
    return list;
}
export {
    getFormatedDate,
    http,
    useQuery,
    renderStatus,
    getNGCurrence,
    Province,
    preparePlotData
};

