
import { message, Upload } from 'antd';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Bars } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../context/auth_context';
import Services from '../../../services/Service';

import '../../../style/signup.css';
import { Province } from '../../../utils';
import { toast, ToastContainer } from 'react-toastify';

function LogComplaint() {
    const accountmodaltrigger = useRef(null);
    const accountmodalclose = useRef(null);
    const [ btnDisable, setBtnDisable ] = useState(false);
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    

    
    let [showError, setShowError] = useState(false);
    const [states, setStates] = useState([]);
    let [ documents, setDocuments ] = useState([]);
    const [ userEmail, setUserEmail ] = useState("");
    const [ isSubmitted, setSubmitted ] = useState(false)

    const { Dragger } = Upload;
    const props = {
        name: 'file',
        multiple: true,
        maxCount: 3,
        action: '',
        accept: "image/png, image/jpeg, image/jpg",
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file);
                setShowError(false);
                setDocuments(prev => [...prev, info.file]);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
       
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    useEffect(() => {

        setStates(Province.getStateObj());
        if(user){
          setValue("staffname", user.fullname);
          setValue("emailaddress", user.email);
          setValue("companyname", user.companyname);
          setValue("phonenumber", user.phonenumber);
        }
    }, []);
 
    const onNext = (data) => {
        // if(documents === null || documents.length === 0){
        //     setShowError(true);
        //   }else{
        //     setShowError(false)
        //   }
        //   if (data && documents.length > 0) {
        //     data.documents = documents;
        //     setUserEmail(data.emailaddress);
        //     onLogComplaint(data);
        //   }
        
          if (data && documents.length > 0) {
            data.documents = documents;
          }else{
            data.documents = [];
          }
            setUserEmail(data.emailaddress);
            onLogComplaint(data);
          
    }
    const onLogComplaint = (data) => {
        setBtnDisable(true);
        setSubmitted(true);
        
        const res = Services.postFormData("/complaints/logcomplaint", data)
        .then(res => {
            if (res) {
                console.log(JSON.stringify(res.data));
                if (res.data.responseCode === "01") {
                    toast.success(res.data.responseDescription, {
                        theme: "light",
                        hideProgressBar: false,
                        onClose: () => {
                            accountmodaltrigger.current.click();
                        }
                    });
                }
                //accountmodaltrigger.current.click();
                // setTimeout(()=>{
                //     accountmodaltrigger.current.click();
                //    }, 1000)
                
            }
        })
        .catch(err => {
            setSubmitted(false);
            console.log("Error: " + err);
        });
        toast.promise(res, {
            pending: "Lodging your complaint, please wait...",
            error: "Something went wrong.",
            //success: {render: ()=> "Successfuly lodged complaint.", onClose:  accountmodaltrigger.current.click()}
        })
    }
    const onHome = () => {
        accountmodalclose.current.click();
        navigate("/");
    }
    const onBack = () => {
        if(user){
            navigate("/dashboard");
        }else{
            navigate("/");
        }
    }
    const onCloseModal = (e) => {
        accountmodalclose.current.click();
        if(user){
            navigate("/dashboard");
        }else{
            navigate("/");
        }
    }
    return ( 
         <>
            <ToastContainer autoClose={1000} />
        <div className="container-fluid">
            <div className="row onboarding">
                <div className="col-md-3 left-banner">
                    <h1 className="title">We are here to support you</h1>
                </div>
                <div className="col-md-9 right-banner d-flex flex-column align-items-center">
                    <div className="row justify-content-end mt-4 close-btn-wrapper">
                        {/* <div className="col-md-12 d-flex justify-content-center align-items-center close-btn" onClick={(e) => {window.history.go(-1); e.preventDefault(); return false;}}>
                         */}
                          <div className="col-md-12 d-flex justify-content-center align-items-center close-btn" onClick={onBack}>
                        
                            <i class="bi bi-x-lg" style={{ color: '#1D2939' }} />
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-10 signup-mobile">

                            <h3 className="title">Log your complaint</h3>
                            <div className="subtitle mb-4">Submit details of your complaints, Our team of certified engineers are ready to help you<br /> resolve all complaints.</div>

                            <form class="row gy-2 signup" onSubmit={handleSubmit(onNext)}>
                                <div class="col-md-6">
                                    <label for="companyname" class="form-label">Company name</label>
                                    <input type="text" class="form-control" id="companyname" placeholder=""
                                        {...register("companyname", { required: true })}
                                    />
                                    {errors.companyname && errors.companyname.type === "required" && <p className="error">Company name is required.</p>}
                                </div>
                                <div class="col-md-6">
                                    <label for="staffname" class="form-label">Staff name</label>
                                    <input type="text" class="form-control" id="staffname" placeholder=""
                                        {...register("staffname", { required: true })}
                                    />
                                    {errors.staffname && errors.staffname.type === "required" && <p className="error">Sraff name is required.</p>}
                                </div>
                                <div class="col-md-6">
                                    <label for="emailaddress" class="form-label">Email address</label>
                                    <input type="text" class="form-control" id="emailaddress" placeholder=""
                                        {...register("emailaddress", { required: true })}
                                    />
                                    {errors.emailaddress && errors.emailaddress.type === "required" && <p className="error">Email is required.</p>}

                                </div>
                                <div class="col-md-6">
                                    <label for="phonenumber" class="form-label">Phone Number</label>
                                    <input type="text" class="form-control" id="phonenumber" placeholder=""
                                        {...register("phonenumber", { required: true })}
                                    />
                                    {errors.phonenumber && errors.phonenumber.type === "required" && <p className="error">Phone number is required.</p>}

                                </div>
                                <div class="col-md-6">
                                    <label for="serialnumber" class="form-label">Product serial number</label>
                                    <input type="text" class="form-control" id="serialnumber" placeholder=""
                                        {...register("productserialnumber", { required: true })}
                                    />
                                    {errors.productserialnumber && errors.productserialnumber.type === "required" && <p className="error">Product serial number is required.</p>}
                                </div>
                                <div class="col-md-6">
                                    <label for="productname" class="form-label">Product name</label>
                                    <input type="text" class="form-control" id="productname" placeholder=""
                                        {...register("productname", { required: true })}
                                    />
                                    {errors.productname && errors.productname.type === "required" && <p className="error">Product name is required.</p>}
                                </div>
                                <div class="col-md-6">
                                    <label for="category" className="form-label">Product category</label>
                                    <select className="form-select" aria-label="Default select example"
                                        {...register("productcategory", { required: true })}
                                    >
                                        <option selected={true} value="">Select category</option>
                                        
                                        <option value="bvn">BVN</option>
                                        <option value="cardprinters">ID Card Printers</option>
                                        <option value="receiptprinters">Receipt Printers</option>
                                        <option value="countingmachines">Counting Machines</option>
                                    </select>
                                    {errors.productcategory && errors.productcategory.type === "required" && <p className="error">Product category is required.</p>}
                                </div>
                                <div class="col-md-6">
                                    <label for="state" className="form-label">State</label>
                                    <select className="form-select" aria-label="Default select example"
                                        {...register("state", { required: true })}
                                    >
                                        <option selected={true} value="">Select state</option>
                                        {
                                                states.map((state, i) => <option key={state.key} value={state.key}>{state.value}</option>)
                                            }
                                    </select>
                                    {errors.state && errors.state.type === "required" && <p className="error">State is required.</p>}
                                </div>
                                <div class="col-md-12">
                                    <label for="companyaddress" class="form-label">Company address</label>
                                    <input type="text" class="form-control" id="companyaddress" placeholder=""
                                        {...register("companyaddress", { required: true })}
                                    />
                                    {errors.companyaddress && errors.companyaddress.type === "required" && <p className="error">Company address is required.</p>}
                                </div>
                                <div class="col-md-12">
                                    <label for="description" class="form-label">Problem description</label>
                                    <textarea class="form-control" id="description" placeholder=""
                                        {...register("problemdescription")}
                                    />
                                    {/* {errors.problemdescription && errors.problemdescription.type === "required" && <p className="error">Problem description is required.</p>} */}

                                </div>
                                <div className="col-md-12 h-auto mb-4">
                                    <div className="row mt-3 mb-5">
                                        <Dragger beforeUpload={() => false} {...props} style={{
                                            fontSize: 14, fontWeight: 400, background: '#F9FAFB',
                                            border: 1, dashed: '#D0D5DD',
                                            borderRadius: '0.125rem',
                                            border: '1px dashed #D0D5DD'
                                        }}
                                            ref={(e) => {

                                            }}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <img src="../assets/images/upload-cloud.svg"></img>
                                            </p>
                                            <p className="ant-upload-text" >Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">
                                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
                                            </p>
                                        </Dragger>

                                        {/* {
                                            showError === true ?
                                                <p className="error">Upload is required.</p> : ""
                                        } */}
                                    </div>
                                </div>
                                {isSubmitted ? "" :
                                <div className="col-md-12 d-flex justify-content-end mt-4">
                                    <button type="submit" className="btn more-btn" style={{ padding: '7px 64px' }}>Submit</button>
                                </div>
                               }
                            </form>
                        </div>
                    </div>
                </div>
            </div>

          
        </div>
          {/* Account created modal */}
          <button
                className="btn btn-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#accountModal"
                style={{ visibility: "hidden" }}
                ref={accountmodaltrigger}
            >
                Account created Modal
            </button>
            <button
                className="btn btn-primary w-100"
                data-bs-dismiss="modal"
                data-bs-target="#accountModal"
                aria-label="Close"
                style={{ visibility: "hidden" }}
                ref={accountmodalclose}
            >
                Close Account created Modal
            </button>
            <div class="modal fade" id="accountModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ width: 720 }}>
                        <div className="row py-3 px-3">
                            <div className="col-md-12 d-flex justify-content-end" style={{ zIndex: 5000 }}>
                                <div className="d-flex justify-content-center align-items-center close-btn" onClick={onCloseModal}>
                                    <i class="bi bi-x-lg" style={{ color: '#1D2939' }} /></div>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div className="d-flex flex-column h-100 justify-content-center align-items-center text-center">
                                <div className="row">
                                    <h3 className="card-title-modal">Congratulations! Your complaint has been <br />lodge successfully.</h3>

                                </div>
                                <img src="../assets/images/account-success.svg" style={{ width: '50%', height: '50%' }}></img>
                                <p className="card-subtitle-modal mt-3">Details of your complaint has been sent to your email address {userEmail}.
                                    Save your tracking ID has it will be needed to monitor your complaint.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Account created model end */}
        </>
    );
}

export default LogComplaint;