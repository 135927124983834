import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import PasswordInput from '../../../components/password/PasswordInput';
import { useAuthContext } from '../../../context/auth_context';
import Services from '../../../services/Service';

import '../../../style/signin.css';

function SignIn() {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const { signIn, logIn } = useAuthContext();
    let password = useRef(null);
    let togglePassword = useRef(null);
    const navigate = useNavigate();

    const onTogglePassword = (e) => {
        let passwordType = password.current.getAttribute("type") === "password" ? "text" : "password";
        password.current.setAttribute("type", passwordType);
        e.target.classList.toggle("bi-eye-slash");
        e.target.classList.toggle("bi-eye")
    }
    const onSubmit = (data) => {
        if (data) {
            const notify = Services.post("/customer/authenticate", data)
                .then(res => {
                    if (res.data.responseCode === "00") {
                        toast.error(res.data.responseDescription, {
                            theme: "light",
                            hideProgressBar: false
                        });
                    } else if (res.data.responseCode === "01") {
                        console.log(res.data);
                            toast.success("Authentication is successful.", {
                                theme: "light",
                                hideProgressBar: false,
                                onClose: () => {
                                    signIn({ ...res.data.data, login: true });
                                    navigate("/dashboard");
                                }
                            });
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                });
            toast.promise(notify,{
                    pending: "Trying to login..."
                })
        }
    }
    return (
        <>
            <ToastContainer autoClose={1000} />
            <div className="accountverified-container signin flex-column">
                <div className="row mb-3">
                    <Link to="/" className="col-md-12">
                    <img src="/assets/images/home-logo.svg" alt="" />
                    </Link>
                </div>
                <div className="row justify-content-center align-items-center d-sm-none d-md-flex m-2">
                    <div className="mcard h-auto signin-card">
                        <div className="d-flex flex-column h-100 justify-content-center align-items-center text-left">
                            <h3 className="title text-center">Sign in</h3>
                            <p className="subtitle">Welcome back, Please sign in to your account.</p>
                            <form className="row" onSubmit={handleSubmit(onSubmit)}>
                                <div class="col-md-12 mb-2">
                                    <label for="email" class="form-label">Email address</label>
                                    <input type="email" class="form-control" id="emailaddress" placeholder=""
                                        {...register("emailaddress", { required: true })}
                                    />
                                    {errors.emailaddress && errors.emailaddress.type === "required" && <p className="error">Email address is required.</p>}

                                </div>
                                <div class="col-md-12 mb-0">
                                    <PasswordInput
                                        label="Password"
                                        name="password"
                                        placeholder="********"
                                        attach={register}
                                        rules={{ required: true }}
                                    />
                                    {errors.password && errors.password.type === "required" && <p className="error">Password is required.</p>}
                                </div>
                                <div className="col-md-12 mb-3">
                                    <Link to="/account/forgetpassword" className="forget-password">Forget Password?</Link>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <button type="submit" class="btn more-btn signin-btn w-100">Submit</button>
                                </div>
                                <div className="col-md-12 mb-0 text-left no-account">
                                    Don't have an account yet? <Link to="/account/signup" className="forget-password">Sign up</Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default SignIn;